exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-category-know-js": () => import("./../../../src/pages/blog/category/know.js" /* webpackChunkName: "component---src-pages-blog-category-know-js" */),
  "component---src-pages-blog-category-video-js": () => import("./../../../src/pages/blog/category/video.js" /* webpackChunkName: "component---src-pages-blog-category-video-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-prismic-blog-post-uid-js": () => import("./../../../src/pages/blog/{prismicBlogPost.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-post-uid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plugins-index-js": () => import("./../../../src/pages/plugins/index.js" /* webpackChunkName: "component---src-pages-plugins-index-js" */),
  "component---src-pages-plugins-shopify-js": () => import("./../../../src/pages/plugins/shopify.js" /* webpackChunkName: "component---src-pages-plugins-shopify-js" */),
  "component---src-pages-plugins-squarespace-js": () => import("./../../../src/pages/plugins/squarespace.js" /* webpackChunkName: "component---src-pages-plugins-squarespace-js" */),
  "component---src-pages-plugins-wordpress-js": () => import("./../../../src/pages/plugins/wordpress.js" /* webpackChunkName: "component---src-pages-plugins-wordpress-js" */),
  "component---src-pages-shopify-js": () => import("./../../../src/pages/shopify.js" /* webpackChunkName: "component---src-pages-shopify-js" */),
  "component---src-pages-squarespace-js": () => import("./../../../src/pages/squarespace.js" /* webpackChunkName: "component---src-pages-squarespace-js" */),
  "component---src-pages-wordpress-js": () => import("./../../../src/pages/wordpress.js" /* webpackChunkName: "component---src-pages-wordpress-js" */)
}

